<script>
import ApiService from "@/services/api.service";
import ImgCarousel from "@/components/ImgCarousel.vue";
import FileList from "@/components/FileList.vue";

export default {
  components: { ImgCarousel, FileList },
  data: () => ({}),
  mounted() {},
  methods: {}
};
</script>

<template>
  <div>
    <b-row>
      <b-col> Dashboard </b-col>
    </b-row>
  </div>
</template>
